<template>
  <div class="author-detail ct-home margin-content page-news">
    <div class="container author-news">
      <div class="dc-header mb-4">
        <b-container fluid class="d-flex align-items-center font-title">
          <router-link
            :to="{ name: $route.params.shopId ? 'library' : 'library domain' }"
          >
            <div>コンテンツ</div>
          </router-link>
          <b-icon icon="chevronRight" class="mx-3"></b-icon>
          <router-link :to="{ name: '' }">
            <div>ニュース</div>
          </router-link>
        </b-container>
      </div>
      <div class="row mb-2">
        <div class="h2 mb-3 font-title">ニュース</div>
      </div>
      <div class="ct-filter mb-5 version-pc">
        <div class="container">
          <div
            class="row align-items-center"
            v-if="listSearch.length"
            style="line-height: 40px"
          >
            <div
              class="ct-search"
              v-for="(value, index) in listSearch"
              :key="index"
            >
              <span
                v-on:click="filter(value.keyWord, index)"
                :class="{ active: active_el == index }"
                class="btn-title"
              >
                {{ value.title }}
              </span>
              <span
                v-if="index != listSearch.length - 1"
                style="margin-left: 30px; margin-right: 30px"
                >|</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="version-sp filter-keyword">
        <input
          v-model="infoNew.keyWord"
          type="text"
          placeholder="キーワードを入力すると検索ができます。"
          v-on:keyup.enter="getListNews(1)"
        />
      </div>
      <div v-if="listNewData && listNewData.data">
        <div v-if="listNewData.data.length" class="news-list">
          <div
            v-for="(value, index) in listNewData.data"
            :key="index"
            class="btn-detail-news"
            :class="'btn-show-detail-new-' + value.id"
            @click="showDetail(value.id, index)"
          >
            <div
              class="row d-flex align-items-center justify-content-between mb-4 btn-title version-pc"
            >
              <div class="col-md-2 col-12">
                <span>{{
                  moment(String(value.post_date)).format("YYYY/MM/DD")
                }}</span>
              </div>
              <div class="col-md-3 col-12 col-sm-6">
                <label class="btn-news btn-title">
                  {{ value.title }}
                </label>
              </div>
              <div class="col-md-6 col-12 col-sm-6" style="font-weight: bold">
                <div
                  v-if="
                    (value.windows_hover_type ===
                      windowsHoverType.countdown_date ||
                      (value.windows_hover_type ===
                        windowsHoverType.countdown_time &&
                        value.time_stop)) &&
                    !value.expired
                  "
                >
                  {{ value.countdown_name }}{{ value.day }}日間{{
                    value.hour
                  }}時間{{ value.minutes }}分{{ value.second }}秒で終了
                </div>
                <div v-if="value.expired">表示期間終了</div>
                <u>
                  {{
                    value.news_title.length > 25
                      ? value.news_title.substr(0, 25) + "..."
                      : value.news_title
                  }}
                </u>
              </div>
            </div>
            <div class="version-sp list-new-sp">
              <div class="d-flex justify-content-between">
                <label class="btn-news btn-title">
                  {{ value.title }}
                </label>
                <span>{{
                  moment(String(value.post_date)).format("YYYY/MM/DD HH:mm:ss")
                }}</span>
              </div>
              <div
                v-if="
                  (value.windows_hover_type ===
                    windowsHoverType.countdown_date ||
                    (value.windows_hover_type ===
                      windowsHoverType.countdown_time &&
                      value.time_stop)) &&
                  !value.expired
                "
                style="text-align: right"
              >
                {{ value.countdown_name }}{{ value.day }}日間{{
                  value.hour
                }}時間{{ value.minutes }}分{{ value.second }}秒で終了
              </div>
              <div v-if="value.expired" style="text-align: right">
                表示期間終了
              </div>
              <div>
                {{
                  value.news_title.length > 25
                    ? value.news_title.substr(0, 25) + "..."
                    : value.news_title
                }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row d-flex mb-4">
          <p class="col-md-12">ニュースはありません</p>
        </div>
        <div class="d-flex justify-content-center position-relative m-auto">
          <CPagination
            v-if="listNewData.data && listNewData.last_page > 1"
            :activePage.sync="page"
            :pages="listNewData.last_page"
            align="center"
          />
        </div>
      </div>
    </div>
    <b-modal
      id="detail-new"
      :title="detailNew ? detailNew.news_title : ''"
      hide-footer
      hide-header
      size="lg"
      @hide="clearDetailNew()"
    >
      <template #modal-header="{}">
        <h5 class="w-100" style="text-align: center">
          {{ detailNew.news_title }}
        </h5>
        <b-button
          size="sm"
          style="background-color: white; border-color: white"
          class="close"
          @click="closeModalEdit()"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </template>
      <div v-if="detailNew" class="px-3 version-pc">
        <div class="position-relative mb-3">
          <h5>
            {{ detailNew.news_title }}
          </h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('detail-new')"
            style="position: absolute; right: 0; top: 0"
          >
            ×
          </button>
        </div>
        <div
          v-if="
            detailNew.windows_hover_type === windowsHoverType.countdown_date ||
            detailNew.windows_hover_type === windowsHoverType.countdown_time
          "
          class="dc-header mb-5"
          style="text-align: center"
        >
          <span
            >{{ detailNew.countdown_name }}{{ daydl }}日間 {{ hourdl }}時間{{
              minutesdl
            }}分{{ seconddl }}秒で終了</span
          >
          <br />
        </div>
        <div class="row d-flex align-items-center mb-4 mt-3">
          <div class="col-md-2 col-12">
            <span>{{
              moment(String(detailNew.post_date)).format("YYYY/MM/DD")
            }}</span>
          </div>
          <div class="col-md-3 col-6">
            <label class="w-100 btn-news">
              {{ detailNew.title }}
            </label>
          </div>
        </div>
        <p
          v-html="detailNew.news_content"
          style="height: 300px; overflow: auto"
        ></p>
        <div class="justify-content-center" style="display: none">
          <b-button
            :disabled="listNewData.current_page === 1 && indexSelect === 0"
            variant="primary"
            class="w-30 mr-3"
            @click="previous()"
          >
            前のニュースへ
          </b-button>
          <b-button
            :disabled="
              listNewData.data &&
              listNewData.current_page === listNewData.last_page &&
              indexSelect === listNewData.data.length - 1
            "
            variant="primary"
            class="w-30"
            @click="next()"
          >
            次のニュースへ
          </b-button>
        </div>
      </div>
      <div v-if="detailNew" class="px-3 version-sp">
        <div class="position-relative mb-3">
          <label class="btn-news">
            {{ detailNew.title }}
          </label>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('detail-new')"
            style="position: absolute; right: 0; top: 0"
          >
            ×
          </button>
        </div>
        <div class="mb-3" style="font-size: 18px">
          {{ detailNew.news_title }}
        </div>
        <div
          v-if="
            detailNew.windows_hover_type === windowsHoverType.countdown_date ||
            detailNew.windows_hover_type === windowsHoverType.countdown_time
          "
          class="mb-3"
          style="font-size: 18px"
        >
          <span
            >{{ detailNew.countdown_name }}{{ daydl }}日間 {{ hourdl }}時間{{
              minutesdl
            }}分{{ seconddl }}秒で終了</span
          >
          <br />
        </div>
        <p
          v-html="detailNew.news_content"
          style="height: 300px; overflow: auto"
        ></p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
import moment from "moment";
export default {
  name: "AuthorDetail",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      infoNew: {
        category: "",
        keyWord: "",
      },
      authors: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 4,
        touchThreshold: 5,
      },
      author: null,
      listContent: [],
      listNews: [],
      page: 1,
      limit: 5,
      listSearch: [],
      active_el: 0,
      detailNew: null,
      indexSelect: null,
      callFirstTime: false,
      direct: null,
      windowsHoverType: Constants.WINDOWS_HOVER_TYPE,
      interval: null,
      daydl: "",
      hourdl: "",
      minutesdl: "",
      seconddl: "",
      listNewData: [],
      today: new Date(),
    };
  },
  components: {
    // VueSlickCarousel,
  },
  created() {
    const request = {
      shop_id: this.shop_id,
      screen: "user",
    };
    this.getListNews(1);
    this.getListCategoryNew(request);
    this.checkDateNew();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        const request = {
          shop_id: this.shop_id,
        };
        this.getUnreadNew(request);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    page() {
      this.listNewData = [];
      this.getListNews(this.page);
    },
    listCategoryNew() {
      this.listSearch = this.listCategoryNew.map((item) => ({
        id: item.id,
        title: item.title,
        keyWord: item.title,
      }));
      this.listSearch.unshift({
        id: "",
        title: "すべて",
        keyWord: "",
      });
    },
    listNew() {
      this.listNewData = this.listNew;
    },
  },
  mounted() {
    setInterval(this.accountTransactions, 1000);
  },
  computed: {
    ...mapGetters([
      "listNew",
      "error",
      "success",
      "message",
      "listCategoryNew",
      "idNewsLog",
    ]),
  },
  methods: {
    ...mapActions({
      getListNew: "getListNew",
      getListCategoryNew: "getListCategoryNew",
      updateTimeRead: "updateTimeRead",
      getUnreadNew: "getUnreadNew",
    }),
    moment,
    accountTransactions() {
      let temp;
      // const self = this;
      if (this.listNew && this.listNew.data && this.listNew.data.length > 0) {
        this.listNew.data.forEach((element) => {
          temp = element.title;
          element.title = new Date().toLocaleString();
          element.title = temp;
          if (
            element.windows_hover_type ===
              this.windowsHoverType.countdown_date ||
            element.windows_hover_type === this.windowsHoverType.countdown_time
          ) {
            const time_stop =
              element.windows_hover_type ===
              this.windowsHoverType.countdown_date
                ? element.countdown_date
                : element.time_stop
                ? element.time_stop
                : this.addMinutes(element.countdown_time_read * 60);
            element["day"] = this.countDown(time_stop).day;
            element["hour"] = this.countDown(time_stop).hour;
            element["minutes"] = this.countDown(time_stop).minutes;
            element["second"] = this.countDown(time_stop).second;
            element["time_stop"] = time_stop;
            if (
              element["day"] === 0 &&
              element["hour"] === 0 &&
              element["minutes"] === 0 &&
              element["second"] === 0
            ) {
              element["expired"] = true;
            }
          }
        });
      }
      if (this.detailNew && Object.keys(this.detailNew).length > 0) {
        const time_stop =
          this.detailNew.windows_hover_type ===
          this.windowsHoverType.countdown_date
            ? this.detailNew.countdown_date
            : this.detailNew.time_stop
            ? this.detailNew.time_stop
            : this.detailNew.countdown_time_read;
        this.daydl = this.countDown(time_stop).day;
        this.hourdl = this.countDown(time_stop).hour;
        this.minutesdl = this.countDown(time_stop).minutes;
        this.seconddl = this.countDown(time_stop).second;
      }
    },
    showDetail(id, index) {
      this.indexSelect = index;
      this.detailNew = this.listNew.data.filter((item) => item.id === id)[0];
      this.checkTimeNew();
    },
    clearDetailNew() {
      this.$store.commit("set", ["idNewsLog", null]);
      this.detailNew = null;
      this.direct = "";
    },
    getListNews(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        screen: "user",
        page: page,
        data: {
          shop_id: this.shop_id,
          type: this.infoNew.category,
          limit: this.limit,
          keyWord: this.infoNew.keyWord,
        },
      };
      this.getListNew(formData);
    },
    next() {
      this.direct = "next";
      this.indexSelect += 1;
      this.detailNew = this.listNew.data.filter(
        (item, index) => index === this.indexSelect
      )[0];
      if (!this.detailNew) {
        this.page += 1;
        this.getListNews(this.page);
      } else {
        this.checkTimeNew();
      }
    },
    previous() {
      this.direct = "previous";
      this.indexSelect -= 1;
      this.detailNew = this.listNew.data.filter(
        (item, index) => index === this.indexSelect
      )[0];
      if (!this.detailNew) {
        this.page -= 1;
        this.getListNews(this.page);
      } else {
        this.checkTimeNew();
      }
    },
    filter(title, index) {
      this.listNewData = [];
      this.active_el = index;
      this.infoNew.category = title;
      this.getListNews(1);
    },
    gotoLibrary() {
      this.$router.push({
        name: this.$route.params.shopId ? "library" : "library domain",
        params: {
          shopId: this.shop_id,
        },
      });
    },
    checkTimeNew() {
      if (this.detailNew && Object.keys(this.detailNew).length > 0) {
        const request = {
          shop_id: this.shop_id,
          new_id: this.detailNew.id,
        };
        this.updateTimeRead(request);
        const typeWindowHover = this.detailNew.windows_hover_type;
        const timeRead = this.detailNew.time_read;
        if (!timeRead) {
          this.listNew.data[this.indexSelect].time_read = 1;
        }
        if (
          typeWindowHover === this.windowsHoverType.one_time_offer &&
          timeRead >= 1
        ) {
          window.location.href = this.detailNew.countdown_url;
          return;
        }
        if (typeWindowHover === this.windowsHoverType.countdown_time) {
          if (!this.detailNew.time_stop) {
            this.listNew.data[this.indexSelect].time_stop = this.addMinutes(
              this.detailNew.countdown_time_read * 60
            );
          }
        }
        if (
          typeWindowHover === this.windowsHoverType.countdown_date &&
          this.detailNew.countdown_date <=
            moment(
              new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })
            ).format("YYYY-MM-DD HH:mm:ss")
        ) {
          return;
        }
        if (typeWindowHover === this.windowsHoverType.countdown_time) {
          if (
            this.detailNew.time_stop &&
            this.detailNew.time_stop <=
              moment(
                new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })
              ).format("YYYY-MM-DD HH:mm:ss")
          ) {
            return;
          }
        }
        if (this.today < new Date(this.detailNew.post_date)) {
          if (this.detailNew.url_redirect) {
            window.location.href = this.detailNew.url_redirect;
            return;
          } else {
            this.$router.push({
              name: this.$route.params.shopId ? "store" : "store domain",
            });
          }
        }
        this.$store.commit("set", ["idNewsLog", this.detailNew.id]);
        this.$bvModal.show("detail-new");
      }
    },
    addMinutes(minutes) {
      return moment(
        new Date(new Date().getTime() + minutes * 60000).toLocaleString(
          "ja-JP",
          { timeZone: "Asia/Tokyo" }
        )
      ).format("YYYY-MM-DD HH:mm:ss");
    },
    checkDateNew() {
      const self = this;
      this.interval = setInterval(function () {
        if (self.detailNew && Object.keys(self.detailNew).length > 0) {
          const typeWindowHover = self.detailNew.windows_hover_type;
          if (
            typeWindowHover === self.windowsHoverType.countdown_date &&
            self.detailNew.countdown_date <=
              moment(
                new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })
              ).format("YYYY-MM-DD HH:mm:ss")
          ) {
            window.location.href = self.detailNew.countdown_url;
            clearInterval(self.interval);
          }
          if (typeWindowHover === self.windowsHoverType.countdown_time) {
            if (
              self.detailNew.time_stop &&
              self.detailNew.time_stop <=
                moment(
                  new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })
                ).format("YYYY-MM-DD HH:mm:ss")
            ) {
              window.location.href = self.detailNew.countdown_url;
              clearInterval(self.interval);
            }
          }
        }
      }, 1000);
    },
    countDown(time_end) {
      let day;
      let hour;
      let minutes;
      let second;
      const current_day = moment(
        new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })
      );
      day = moment(time_end).diff(current_day, "days");
      hour =
        moment(time_end).diff(current_day, "hour") -
        moment(time_end).diff(current_day, "days") * 24;
      minutes =
        moment(time_end).diff(current_day, "minutes") -
        moment(time_end).diff(current_day, "hour") * 60;
      second =
        moment(time_end).diff(current_day, "seconds") -
        moment(time_end).diff(current_day, "minutes") * 60;
      return { day: day, hour: hour, minutes: minutes, second: second };
    },
  },
};
</script>
<style lang="scss">
.router-library:hover {
  cursor: pointer;
}
.btn-title:hover {
  cursor: pointer;
}
.dc-header {
  padding-top: 20px;
  font-size: 18px;
  a {
    color: #000;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }
}
.btn-news {
  color: #7f7f7f;
  border: 2px solid #7f7f7f;
  border-radius: 5px;
  background-color: yellow;
  text-align: center;
  width: 150px;
}
.filter-keyword {
  text-align: center;
  padding: 0 30px;
  margin-bottom: 20px;
  input {
    border-radius: 10px;
    background: #e5e5e5;
    border: none;
    height: 35px;
    width: 100%;
  }
}
.list-new-sp {
  border-radius: 10px;
  box-shadow: 0px 3px 6px #7681929c;
  padding: 10px 20px;
  margin-bottom: 20px;
}
</style>
